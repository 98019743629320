'use client'

import type { PropsWithChildren, ReactNode } from 'react'
import { useEffect, useState } from 'react'

type ClientOnlyProps = PropsWithChildren<{
  fallback?: ReactNode
}>
export function ClientOnly({ children, fallback }: ClientOnlyProps) {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])
  if (!isClient) {
    return fallback || null
  }

  return children
}
