import { AnimatePresence, motion } from 'motion/react'

import { Toast } from '../toast'

import type { AnimatedToastListProps } from './animated-toast-list.types'

export function AnimatedToastList({ toasts }: AnimatedToastListProps) {
  return (
    <AnimatePresence>
      {toasts.map((toastProps) => (
        <motion.div
          key={toastProps.key}
          layout
          initial={{ opacity: 0, y: 50, scale: 0.3 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, height: 0, scale: 0.5 }}
          transition={{ y: { type: 'spring' } }}
        >
          <Toast {...toastProps} />
        </motion.div>
      ))}
    </AnimatePresence>
  )
}
