'use client'

import { Paragraph, Heading, Button, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useEffect } from 'react'

import { usePreviousValue } from '../../hooks/use-previous-value'
import { useAdminState } from '../../data/admin-state'
import { useAuthContext } from '../../context/auth-context'
import { getPath } from '../../routing/get-path'
import { useRouter } from '../../vendor/next'

const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: 'fit-content',
  backgroundColor: 'rgba(255, 215, 0)',
  // borderRadius: theme.radii.full,
  padding: theme.spacing['2x'],
  paddingLeft: theme.spacing['5x'],
  zIndex: theme.zIndices.toast,
  pointerEvents: 'none',
}))
const LogoutButton = styled(Button)({
  pointerEvents: 'all',
})

export function AdminBox() {
  const { push } = useRouter()
  const { isAdminLoggedInAsUser, setAdminLoggedInAsUser } = useAdminState()
  const { authBody, isAuthenticated } = useAuthContext()
  const isPreviouslyLoggedInAsAdmin = usePreviousValue(isAdminLoggedInAsUser)
  const hasLoggedOut = isPreviouslyLoggedInAsAdmin && !isAdminLoggedInAsUser

  useEffect(() => {
    if (hasLoggedOut) {
      push(getPath('adminLoginAsUser'))
    }
  }, [hasLoggedOut, push])

  const handleLogout = () => setAdminLoggedInAsUser()

  if (!isAdminLoggedInAsUser || !isAuthenticated) return null

  return (
    <Wrapper direction="row" alignItems="center" gap={'2x'}>
      <Paragraph>
        {'Logged in as '}
        <Heading as="span" size="2xs">
          {authBody?.private.email}
        </Heading>
      </Paragraph>
      <LogoutButton variant={'tertiary'} onClick={handleLogout} size="xs">
        {'Log out'}
      </LogoutButton>
    </Wrapper>
  )
}
