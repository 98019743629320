import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Paragraph, Button, Stack, Label, Switch } from '@qasa/qds-ui'
import { useUserConsentContext } from '@qasa/app'
import { Dialog } from '@qasa/app/src/components/dialog'

type CookieSectionProps = {
  title: string
  description: string
  isChecked?: boolean
  isObligatory?: boolean
  onCheckedChange?: (value: boolean) => void
}
function CookieSection({ isChecked, title, description, isObligatory, onCheckedChange }: CookieSectionProps) {
  const { t } = useTranslation('general_cookie_consent')

  if (isObligatory) {
    return (
      <Stack key={title} gap="2x">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Label as="h4">{title}</Label>
          <Paragraph color="subtle">{t('cookie_sections.obligatory')}</Paragraph>
        </Stack>
        <Paragraph color="subtle" size="sm">
          {description}
        </Paragraph>
      </Stack>
    )
  }

  return (
    <Switch label={title} helperText={description} onCheckedChange={onCheckedChange} isChecked={isChecked} />
  )
}

type CookieConsentDialogProps = {
  isOpen: boolean
  setIsOpen: (newValue: boolean) => void
}
export function CookieConsentDialog({ isOpen, setIsOpen }: CookieConsentDialogProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const { t } = useTranslation('general_cookie_consent')
  const { setServiceConsentValues, getServiceConsentValues } = useUserConsentContext()
  const initialServiceConsentValues = getServiceConsentValues()
  const [isMarketingCookieAccepted, setIsMarketingCookieAccepted] = useState(
    initialServiceConsentValues.marketing,
  )
  const [isAnalyticsCookieAccepted, setIsAnalyticsCookieAccepted] = useState(
    initialServiceConsentValues.analytics,
  )
  const handleExpand = () => {
    setIsExpanded(true)
  }

  const handleAcceptAll = () => {
    setIsAnalyticsCookieAccepted(true)
    setIsMarketingCookieAccepted(true)
    setServiceConsentValues({
      hasConsentedToAnalytics: true,
      hasConsentedToMarketing: true,
    })
    setIsOpen(false)
  }

  const handleAcceptSelected = () => {
    setServiceConsentValues({
      hasConsentedToAnalytics: isAnalyticsCookieAccepted,
      hasConsentedToMarketing: isMarketingCookieAccepted,
    })
    setIsOpen(false)
  }

  return (
    <Dialog onOpenChange={setIsOpen} isOpen={isOpen} isCloseButtonVisible={false} size="sm">
      <Dialog.Header hasSidePadding>
        <Dialog.Title>{t('title')}</Dialog.Title>
      </Dialog.Header>
      <Dialog.Body>
        <Stack gap="2x">
          <Stack gap="8x">
            <Paragraph size="lg">{t('description')}</Paragraph>
            {isExpanded && (
              <Stack gap="6x">
                <CookieSection
                  title={t('cookie_sections.necessary.title')}
                  description={t('cookie_sections.necessary.description')}
                  isObligatory
                />
                <CookieSection
                  title={t('cookie_sections.marketing.title')}
                  description={t('cookie_sections.marketing.description')}
                  isChecked={isMarketingCookieAccepted}
                  onCheckedChange={setIsMarketingCookieAccepted}
                />
                <CookieSection
                  title={t('cookie_sections.analytics.title')}
                  description={t('cookie_sections.analytics.description')}
                  isChecked={isAnalyticsCookieAccepted}
                  onCheckedChange={setIsAnalyticsCookieAccepted}
                />
              </Stack>
            )}
          </Stack>
          <Dialog.Footer hasBorder={false}>
            <Stack gap="2x" direction="row">
              {isExpanded && (
                <Button onClick={handleAcceptSelected} variant="primary">
                  {t('buttons.accept_selected')}
                </Button>
              )}
              <Button onClick={handleAcceptAll} variant={isExpanded ? 'tertiary' : 'primary'}>
                {t('buttons.accept_all')}
              </Button>
              {!isExpanded && (
                <Button variant="tertiary" onClick={handleExpand}>
                  {t('buttons.manage_cookies')}
                </Button>
              )}
            </Stack>
          </Dialog.Footer>
        </Stack>
      </Dialog.Body>
    </Dialog>
  )
}
