import(/* webpackMode: "eager" */ "/vercel/path0/apps/p2/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/p2/src/app/fonts.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/p2/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdminBox"] */ "/vercel/path0/apps/p2/src/ui-page-modules/admin-box/admin-box.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IntercomBubble"] */ "/vercel/path0/apps/p2/src/ui-page-modules/intercom-settings/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageViewTracking"] */ "/vercel/path0/apps/p2/src/ui-shared/page-view-tracking.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScamWarningBanner"] */ "/vercel/path0/apps/p2/src/ui-shared/scam-warning-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAnalytics"] */ "/vercel/path0/apps/p2/src/vendor/google-analytics.tsx");
