'use client'
import { useCallback, useEffect, useState } from 'react'
import { getUnixTime } from 'date-fns'
import type { MeQueryQuery } from '@qasa/graphql'

import { useAuthContext } from '../../context/auth-context'
import type { AuthBodyType } from '../../context/auth-context'
import { ENV } from '../../helpers/env'
import { currentBrand } from '../../config'
import { usePathname } from '../../vendor/next'

const APP_ID = ENV.INTERCOM_APP_ID

declare const window: {
  Intercom: Intercom_.IntercomStatic | undefined
} & Window

const getIntercomUserDataFromAuthBody = ({ authBody }: { authBody: NonNullable<MeQueryQuery['me']> }) => {
  return {
    user_id: authBody.uid,
    created_at: getUnixTime(new Date(authBody.createdAt)),
    email: authBody.private.email,
    phone: authBody.private.phoneNumber ?? undefined,
    name: authBody.firstName + ' ' + authBody.private.familyName,
    family_name: authBody.private.familyName,
  }
}

export function useIntercomBubble({ isActive = true }: { isActive: boolean }) {
  const { authBody } = useAuthContext()
  const [isLoaded, setIsLoaded] = useState(false)

  const bootIntercom = useCallback((authBody: AuthBodyType) => {
    if (!APP_ID) {
      return
    }
    if (authBody) {
      window.Intercom?.('boot', {
        app_id: APP_ID,
        ...getIntercomUserDataFromAuthBody({ authBody }),
      })
    } else {
      window.Intercom?.('boot', {
        app_id: APP_ID,
      })
    }
    // Wait for Intercom to boot (max 30 seconds)
    const timeout = setTimeout(() => clearInterval(interval), 30000)
    const interval = setInterval(() => {
      if (window.Intercom?.booted) {
        setIsLoaded(true)
        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 300)
  }, [])

  const shutdownIntercom = useCallback(() => {
    window.Intercom?.('shutdown')
    setIsLoaded(false)
  }, [])

  useEffect(() => {
    if (isLoaded && !isActive) {
      shutdownIntercom()
    }
    if (!isLoaded && isActive) {
      bootIntercom(authBody)
    } else if (authBody) {
      window.Intercom?.('update', getIntercomUserDataFromAuthBody({ authBody }))
    }
  }, [authBody, isActive, isLoaded, bootIntercom, shutdownIntercom])

  useEffect(() => {
    return () => {
      /**
       *  Temporary handling on qasa.com to close bubble when moving away from /help/finland page.
       *  Will be removed once we move all support chats to Zendesk sunshine
       */
      if (currentBrand === 'dotcom') {
        shutdownIntercom()
      }
    }
  }, [shutdownIntercom])

  return { open: () => window.Intercom?.('show'), isLoaded, close: shutdownIntercom }
}

export function IntercomBubble() {
  const pathname = usePathname()

  const isDotcom = currentBrand === 'dotcom'
  const isActiveOnHelpPageForDotcom = isDotcom && pathname.startsWith('/help/finland')
  const isDisabledOnRoute = [
    '/find-home',
    '/embedded-find-home',
    // Match sub route of '/home' such as '/home/123'
    // but not '/home'
    '/home/',
    '/messages/',
  ].some((path) => pathname.startsWith(path))
  // NOTE: It should always be active for dotcom on the help page for finland, but no other page.
  const isActive = ((!isDotcom && !isDisabledOnRoute) || isActiveOnHelpPageForDotcom) && Boolean(APP_ID)

  useIntercomBubble({ isActive })

  return null
}

export const useIntercomBubbleWithBottomMargin = (marginBottom = 80) => {
  useEffect(() => {
    const intercomBubble: HTMLElement | null = document.querySelector('.intercom-lightweight-app-launcher')
    if (intercomBubble) {
      intercomBubble.style.transform = `translateY(-${marginBottom}px)`
    }
    return () => {
      if (intercomBubble) {
        intercomBubble.style.marginBottom = ''
      }
    }
  })
}
