'use client'

import { useTranslation } from 'react-i18next'
import { Link, Paragraph } from '@qasa/qds-ui'

import { EXTERNAL_LINKS } from '../config/external-links'
import { BRAND_CONFIG } from '../config/brand-configurations'

import { Banner } from './banner'
import { ClientOnly } from './client-only'

export function ScamWarningBanner() {
  const { t } = useTranslation('scam-warning-banner')
  return (
    <ClientOnly>
      <Banner name="scam-warning" variant="warning">
        <Paragraph>
          {t('content', { brandName: BRAND_CONFIG.name })}{' '}
          <Link href={EXTERNAL_LINKS.securitySupportUrl} target="_blank">
            {t('read_more_link')}
          </Link>
        </Paragraph>
      </Banner>
    </ClientOnly>
  )
}
