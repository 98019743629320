const fallbackLng = 'dev'
const defaultNS = 'commons'

export function getOptions(lng = fallbackLng, ns: string | string[] = defaultNS) {
  return {
    fallbackLng,
    lng,
    ns,
    defaultNS,
    fallbackNS: defaultNS,
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
    compatibilityJSON: 'v3' as const,
  }
}
